
import { IUser } from '@/modules/system/types';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPlace } from '../types';
import $app from '@/plugins/modules'

@Component
export default class ClinicPlace extends Vue {
  @Prop({ default: () => { } })
  readonly place: IPlace | undefined;

  storing = false;
  showDoctor = false;
  appendDoctor = false;
  currentDoctor: IUser | null = null;

  get newDoctors() {
    return $app.list('DoctorsGlossary', 'byValue').filter(el => !this.place?.doctors.some(doc => doc.id === el.code));
  }

  append() {
    this.currentDoctor = {
      id: undefined,
      state: 'a',
      name: '',
      language: 'en',
      fullName: $app.i18n('system.Append'),
      company: '',
      clinic: '',
      phone: '',
      email: '',
      logo: '',
      background: '',
      toolbarDamage: null,
      taxCode: '',
      profCode: '',
      workNumber: '',
      homePage: '',
      rowsPerPage: 0,
      color: '#FF0000',
      companyId: 0,
      companyNVD: '',
      roles: [],
      clinics: [],
      totalIncome: 0,
      totalIncomeNVD: 0,
      totalDebt: 0,
      processing: false
    }
    this.appendDoctor = true;
    this.showDoctor = true;
  }

  edit(doctor: IUser) {
    this.currentDoctor = doctor;
    this.appendDoctor = false;
    this.showDoctor = true;
  }

  async nameChanged() {
    this.storing = true;
    try {
      await $app.post('/api/clinics/places', this.place);
    } catch (error) {
      $app.pushError(error);
    }
    this.storing = false;
  }

  async remove(doctor: IUser, index: number) {
    doctor.processing = true;
    try {
      await $app.delete('/api/clinics/places/' + this.place?.id + '/doctors/' + doctor.id);
      this.place?.doctors.splice(index, 1);
    } catch (error) {
      $app.pushError(error);
    }
    doctor.processing = false;
  }

  async store() {
    this.storing = true;
    try {
      let doctor = this.currentDoctor!;
      doctor = await $app.post('/api/clinics/places/' + this.place?.id + '/doctors', doctor);
      if (this.appendDoctor) {
        doctor.fullName = $app.label('DoctorsGlossary', doctor.id!);
        this.place?.doctors.push(doctor);
      }
      this.showDoctor = false;
    } catch (error) {
      $app.pushError(error);
    }
    this.storing = false;
  }

  async removePlace() {
    this.storing = true;
    try {
      await $app.delete('/api/clinics/places/' + this.place?.id);
      this.$emit('delete', this.place)
    } catch (error) {
      $app.pushError(error);
    }
    this.storing = false;
  }
}
